









































































































































import { Component, Vue } from 'vue-property-decorator';

// Service Import
import { SupplierZoneService } from '@/services/supplierZones/supplierZoneService';

// Class Import
import { ISupplierZone } from '@/classes/supplierZones/ISupplierZone';
import { SupplierZone } from '@/classes/supplierZones/supplierZone';

// State module imports
import { ZonesModule } from '@/store/zones/zones';
import { HeaderModule } from '@/store/header/header';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { ZoneStatus } from '@/classes/supplierZones/zoneStatus';

// Component Import
import LoadingData from '@/components/datadisplay/LoadingData.vue';
import Tooltip from '@/components/zones/ToolTip.vue';

@Component({
	components: { LoadingData, Tooltip },
})
export default class Zones extends Vue {
	zoneName = '';
	supplierZonesService = new SupplierZoneService();
	zonesModule = ZonesModule;
	headerModule = HeaderModule;
	zoneStatus = ZoneStatus;
	showCreateZoneModal = false;
	isFormValid = false;

	async AddZone() {
		const newSupplierZone = new SupplierZone();
		newSupplierZone.Id = -Math.abs(this.zonesModule.GetZones.length);
		newSupplierZone.SupplierId = SuppliersModule.GetSelectedSupplier.SupplierId;
		newSupplierZone.ZoneName = this.zoneName;

		const newDatabaseZone = await this.supplierZonesService.CreateSupplierZone(
			newSupplierZone
		);

		this.zonesModule.AddZone(newDatabaseZone);
		this.zoneName = '';
		this.showCreateZoneModal = false;

		(this.$refs.zoneForm as any).resetValidation();
	}

	async EditZone(zone: ISupplierZone) {
		if (!zone.ZoneName) {
			return;
		}

		if (!zone.ParentId) {
			const newZoneId = await this.supplierZonesService.CloneSupplierZone(
				zone.Id
			);
			this.$router.push(`zones/${newZoneId}/region`);
		} else {
			this.$router.push(`zones/${zone.Id}/region`);
		}

		this.headerModule.UpdateTitle(zone.ZoneName);
	}

	ShowMenu() {
		event?.preventDefault();
		event?.stopImmediatePropagation();
		event?.stopPropagation();
	}

	async mounted() {
		this.zonesModule.UpdateSelectedGroupZone([]);

		if (SuppliersModule.GetSelectedSupplier.SupplierId) {
			const zones = await this.supplierZonesService.GetSupplierZones(
				SuppliersModule.GetSelectedSupplier.SupplierId
			);
			this.zonesModule?.UpdateZones(zones);
		}

		this.headerModule.UpdateTitle('');
	}

	GetZoneStatusIndicatorColour(status: ZoneStatus) {
		switch (status) {
			case ZoneStatus.Draft:
				return 'var(--v-secondary-darken4)';

			case ZoneStatus.Live:
				return 'var(--v-primary-base)';

			case ZoneStatus.PendingReview:
				return 'var(--v-tertiary-base)';

			case ZoneStatus.ReviewRejected:
				return 'var(--v-error-base)';

			default:
				return '';
		}
	}
}
