var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',[(_vm.zonesModule.GetZones.length === 0)?_c('loading-data',{attrs:{"loadingDataText":'Getting Zones...'}}):_vm._e(),(_vm.zonesModule)?_c('v-row',[_c('v-col',{attrs:{"lg":"3","md":"4","sm":"6","xs":"12","xl":"2"}},[_c('v-card',{staticClass:"justify-center align-center d-flex flex-column zone-card",staticStyle:{"height":"100%","min-height":"200px"},attrs:{"id":"create-zone"},on:{"click":function($event){_vm.showCreateZoneModal = true}}},[_c('v-icon',{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v("mdi-plus-circle")]),_c('v-card-title',{staticClass:"white--text pa-0"},[_vm._v("Create Zone")])],1)],1),_vm._l((_vm.zonesModule.GetZones),function(zone){return _c('v-col',{key:zone.Id,attrs:{"lg":"3","md":"4","sm":"6","xs":"12","xl":"2"},on:{"click":function($event){return _vm.EditZone(zone)}}},[_c('v-card',{staticClass:"zone-card",style:(("border-left: 5px solid " + (_vm.GetZoneStatusIndicatorColour(
							zone.Status
						)))),attrs:{"id":("zone-tooltip-" + (zone.Id))}},[_c('div'),(zone.Status === _vm.zoneStatus.ReviewRejected)?_c('v-tooltip',{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"padding":"10px","position":"absolute","z-index":"100","font-size":".7em"}},'div',attrs,false),on),[_c('v-icon',{staticStyle:{"vertical-align":"bottom","margin-right":"5px"},attrs:{"small":"","color":"error"}},[_vm._v("mdi-information")]),_c('span',{staticClass:"white--text"},[_vm._v("Action Required")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(zone.RejectionReason))])]):_vm._e(),_c('v-img',{staticStyle:{"position":"absolute","opacity":"0.5"},attrs:{"src":require("@/assets/images/Map.webp")}}),_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":"200px"}},[_c('v-card-title',[_vm._v(_vm._s(zone.ZoneName))])],1)],1),(zone.Status !== _vm.zoneStatus.ReviewRejected)?_c('Tooltip',{attrs:{"activator":("#zone-tooltip-" + (zone.Id)),"toolTipText":("" + (_vm.zoneStatus[zone.Status].replace(/[A-Z]/g, ' $&').trim()))}}):_vm._e()],1)})],2):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showCreateZoneModal),callback:function ($$v) {_vm.showCreateZoneModal=$$v},expression:"showCreateZoneModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" Create Zone ")]),_c('v-card-text',{staticClass:"pa-4"},[_c('v-form',{ref:"zoneForm",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{staticClass:"pa-3",attrs:{"label":"Zone Name","floating":"","rules":[
							function (v) { return !!v || 'Zone Name Required'; },
							function (v) { return (v && v.length > 10) ||
								'Description must be greater than 10 characters'; },
							function (v) { return (v && v.length < 255) ||
								'Number must be less than 255 characters'; } ]},model:{value:(_vm.zoneName),callback:function ($$v) {_vm.zoneName=$$v},expression:"zoneName"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-7",attrs:{"disabled":!_vm.isFormValid,"bottom":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.AddZone()}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }