<template>
	<div>
		<v-tooltip left color="secondary" :activator="activator">{{
			toolTipText
		}}</v-tooltip>
	</div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class Tooltip extends Vue {
	@Prop({ default: '' }) toolTipText;
	@Prop({}) activator = '';
}
</script>
